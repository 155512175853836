.inch-ruler-container-landscape  {
    width: 100% !important;
    top: 239px !important;
}

.ruler-container-landscape, .inch-ruler-container-landscape {
    width: 168px;
    position: absolute;
    top: 226px;
    left: 0;

    .ruler, 
    .inch-ruler {
        position: relative;
        width: 101%;
        margin: 20px auto;
        height: 14px;
        color: white;
       }
    .inch-ruler {
        width: 114%;
    }
       .ruler .cm,
       .ruler .mm {
        position: absolute;
        border-left: 1px solid white;
        height: 14px;
        width: 10%;
       }
       .ruler .mm {
        width: 5% !important;
       }
       .ruler .cm:after,
       .inch-ruler .cm:after {
        position: absolute;
        bottom: -15px;
        font: 11px/1 sans-serif;
        right: 48px;
       }
       .inch-ruler .cm:after {
        right: 125px;
       }
       .ruler .mm {
        height: 5px;
       }
       .ruler .mm:nth-of-type(5) {
        height: 10px;
       }

       .inch-ruler .mm:nth-of-type(10) {
        height: 20px !important; 
       } 

       .inch-ruler .mm:nth-of-type(even) {
        height: 10px !important; 
       } 

       .inch-ruler .mm:nth-of-type(5) {
        height: 5px !important; 
       } 
       .ruler .cm:nth-of-type(1) {
        left: 0%;
       }
       .ruler .cm:nth-of-type(1):after {
        content: "0";
       }
       .ruler .cm:nth-of-type(2) {
        left: 30%;
       }
       .inch-ruler .cm:nth-of-type(2) {
        left: 44%;
       }
       .ruler .cm:nth-of-type(2):after {
        content: "1";
       }
       .ruler .cm:nth-of-type(3) {
        left: 60%;
       }
       .inch-ruler .cm:nth-of-type(3) {
        left: 88%;
       }
       .ruler .cm:nth-of-type(3):after {
        content: "2";
       }
       .ruler .cm:nth-of-type(4) {
        left: 90%;
       }
       .ruler .cm:nth-of-type(4):after {
        content: "3";
       }
       .ruler .cm:nth-of-type(5) {
        left: 120%;
        .mm {
            display: none;
        }
       }
       .ruler .cm:nth-of-type(5):after {
        content: "4";
       }
       .ruler .cm:nth-of-type(6) {
        left: 150%;
        display: none;
       }
       .ruler .cm:nth-of-type(6):after {
        content: "5";
        display: none;
       }
       .ruler .cm:nth-of-type(7) {
        left: 60%;
       }
       .ruler .cm:nth-of-type(7):after {
        content: "6";
       }
       .ruler .cm:nth-of-type(8) {
        left: 70%;
       }
       .ruler .cm:nth-of-type(8):after {
        content: "7";
       }
       .ruler .cm:nth-of-type(9) {
        left: 80%;
       }
       .ruler .cm:nth-of-type(9):after {
        content: "8";
       }
       .ruler .cm:nth-of-type(10) {
        left: 90%;
       }
       .ruler .cm:nth-of-type(10):after {
        content: "9";
       }
       .ruler .cm:nth-of-type(11) {
        left: 100%;
       }
       .ruler .cm:nth-of-type(11):after {
        content: "10";
       }
       .ruler .mm:nth-of-type(1) {
        left: 10%;
       }
       .ruler .mm:nth-of-type(2) {
        left: 20%;
       }
       .ruler .mm:nth-of-type(3) {
        left: 30%;
       }
       .ruler .mm:nth-of-type(4) {
        left: 40%;
       }
       .ruler .mm:nth-of-type(5) {
        left: 50%;
       }
       .ruler .mm:nth-of-type(6) {
        left: 60%;
       }
       .ruler .mm:nth-of-type(7) {
        left: 70%;
       }
       .ruler .mm:nth-of-type(8) {
        left: 80%;
       }
       .ruler .mm:nth-of-type(9) {
        left: 90%;
       }
       .ruler .mm:nth-of-type(10) {
        left: 100%;
       }

       .inch-ruler .mm:nth-of-type(1) {
        left: 5% !important;
       }
       .inch-ruler .mm:nth-of-type(2) {
        left: 10% !important;
       }
       .inch-ruler .mm:nth-of-type(3) {
        left: 15% !important;
       }
       .inch-ruler .mm:nth-of-type(4) {
        left: 20% !important;
       }
       .inch-ruler .mm:nth-of-type(5) {
        left: 25% !important;
       }
       .inch-ruler .mm:nth-of-type(6) {
        left: 30% !important;
       }
       .inch-ruler .mm:nth-of-type(7) {
        left: 35% !important;
       }
       .inch-ruler .mm:nth-of-type(8) {
        left: 40% !important;
       }
       .inch-ruler .mm:nth-of-type(9) {
        left: 45% !important;
       }
       .inch-ruler .mm:nth-of-type(10) {
        left: 50% !important;
       }
       .inch-ruler .mm:nth-of-type(11) {
        left: 55% !important;
       }
       .inch-ruler .mm:nth-of-type(12) {
        left: 60% !important;
       }
       .inch-ruler .mm:nth-of-type(13) {
        left: 65% !important;
       }
       .inch-ruler .mm:nth-of-type(14) {
        left: 70% !important;
       }
       .inch-ruler .mm:nth-of-type(15) {
        left: 75% !important;
       }
       .inch-ruler .mm:nth-of-type(16) {
        left: 80% !important;
       }
       .inch-ruler .mm:nth-of-type(17) {
        left: 85% !important;
       }
       .inch-ruler .mm:nth-of-type(18) {
        left: 90% !important;
       }
       .inch-ruler .mm:nth-of-type(19) {
        left: 95% !important;
       }
       .inch-ruler .mm:nth-of-type(20) {
        left: 100% !important;
       }
}

.inch-ruler-container-portrait {
    width: 100% !important;
    top: 101px !important;
    left: -137px !important;
}

.ruler-container-portrait,
.inch-ruler-container-portrait {
    width: 168px;
    position: absolute;
    top: 48px;
    rotate: 90deg;
    left: -93px;

    .ruler,
    .inch-ruler {
        position: relative;
        width: 101%;
        margin: 20px auto;
        height: 14px;
        color: white;
       }
       .inch-ruler {
        width: 114%;
       }
       .ruler .cm,
       .ruler .mm {
        position: absolute;
        border-left: 1px solid white;
        height: 14px;
        width: 10%;
       }
       .ruler .cm:after,
       .inch-ruler .cm:after {
        position: absolute;
        bottom: -15px;
        font: 11px/1 sans-serif;
        right: 48px;
       }

       .inch-ruler .cm:after {
        right: 125px;
       }

       .ruler .mm {
        height: 5px;
       }
       .ruler .mm:nth-of-type(5) {
        height: 10px;
       }

       .inch-ruler .mm:nth-of-type(10) {
        height: 20px !important; 
       } 

       .inch-ruler .mm:nth-of-type(even) {
        height: 10px !important; 
       } 

       .inch-ruler .mm:nth-of-type(5) {
        height: 5px !important; 
       } 
       .ruler .cm:nth-of-type(1) {
        left: 0%;
       }
       .ruler .cm:nth-of-type(1):after {
        content: "5";
       }

       .inch-ruler .cm:nth-of-type(1):after {
        content: "2" !important;
       }

       .ruler .cm:nth-of-type(2) {
        left: 30%;
       }
       .inch-ruler .cm:nth-of-type(2) {
        left: 44%;
       }
       .ruler .cm:nth-of-type(2):after {
        content: "4";
       }

       .inch-ruler .cm:nth-of-type(2):after {
        content: "1" !important;
       }

       .ruler .cm:nth-of-type(3) {
        left: 60%;
       }
       .inch-ruler .cm:nth-of-type(3) {
        left: 88%;
       }
       .ruler .cm:nth-of-type(3):after {
        content: "3";
       }
       .inch-ruler .cm:nth-of-type(3):after {
        content: "0" !important;
       }
       .ruler .cm:nth-of-type(4) {
        left: 90%;
       }
       .ruler .cm:nth-of-type(4):after {
        content: "2";
       }
       .ruler .cm:nth-of-type(5) {
        left: 120%;
       }
       .ruler .cm:nth-of-type(5):after {
        content: "1";
       }
       .ruler .cm:nth-of-type(6) {
        left: 150%;
       }
       .ruler .cm:nth-of-type(6):after {
        content: "0";
       }
       .ruler .cm:nth-of-type(7) {
        left: 180%;
       }
       .ruler .cm:nth-of-type(7):after {
        content: "6";
       }
       .ruler .cm:nth-of-type(8) {
        left: 70%;
       }
       .ruler .cm:nth-of-type(8):after {
        content: "7";
       }
       .ruler .cm:nth-of-type(9) {
        left: 80%;
       }
       .ruler .cm:nth-of-type(9):after {
        content: "8";
       }
       .ruler .cm:nth-of-type(10) {
        left: 90%;
       }
       .ruler .cm:nth-of-type(10):after {
        content: "9";
       }
       .ruler .cm:nth-of-type(11) {
        left: 100%;
       }
       .ruler .cm:nth-of-type(11):after {
        content: "10";
       }
       
       .ruler .mm:nth-of-type(1) {
        left: 10%;
       }
       .ruler .mm:nth-of-type(2) {
        left: 20%;
       }
       .ruler .mm:nth-of-type(3) {
        left: 30%;
       }
       .ruler .mm:nth-of-type(4) {
        left: 40%;
       }
       .ruler .mm:nth-of-type(5) {
        left: 50%;
       }
       .ruler .mm:nth-of-type(6) {
        left: 60%;
       }
       .ruler .mm:nth-of-type(7) {
        left: 70%;
       }
       .ruler .mm:nth-of-type(8) {
        left: 80%;
       }
       .ruler .mm:nth-of-type(9) {
        left: 90%;
       }
       .ruler .mm:nth-of-type(10) {
        left: 100%;
       }

       .inch-ruler .mm:nth-of-type(1) {
        left: 5% !important;
       }
       .inch-ruler .mm:nth-of-type(2) {
        left: 10% !important;
       }
       .inch-ruler .mm:nth-of-type(3) {
        left: 15% !important;
       }
       .inch-ruler .mm:nth-of-type(4) {
        left: 20% !important;
       }
       .inch-ruler .mm:nth-of-type(5) {
        left: 25% !important;
       }
       .inch-ruler .mm:nth-of-type(6) {
        left: 30% !important;
       }
       .inch-ruler .mm:nth-of-type(7) {
        left: 35% !important;
       }
       .inch-ruler .mm:nth-of-type(8) {
        left: 40% !important;
       }
       .inch-ruler .mm:nth-of-type(9) {
        left: 45% !important;
       }
       .inch-ruler .mm:nth-of-type(10) {
        left: 50% !important;
       }
       .inch-ruler .mm:nth-of-type(11) {
        left: 55% !important;
       }
       .inch-ruler .mm:nth-of-type(12) {
        left: 60% !important;
       }
       .inch-ruler .mm:nth-of-type(13) {
        left: 65% !important;
       }
       .inch-ruler .mm:nth-of-type(14) {
        left: 70% !important;
       }
       .inch-ruler .mm:nth-of-type(15) {
        left: 75% !important;
       }
       .inch-ruler .mm:nth-of-type(16) {
        left: 80% !important;
       }
       .inch-ruler .mm:nth-of-type(17) {
        left: 85% !important;
       }
       .inch-ruler .mm:nth-of-type(18) {
        left: 90% !important;
       }
       .inch-ruler .mm:nth-of-type(19) {
        left: 95% !important;
       }
       .inch-ruler .mm:nth-of-type(20) {
        left: 100% !important;
       }
}