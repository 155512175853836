.why-choose-us {
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}
.why-choose-us h1 {
    color: var(--app-primary-5);
    margin-bottom: 20px;
}
.why-choose-us .features {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 1em auto;
}
.why-choose-us .feature {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    text-align: left;
}
.why-choose-us .feature i {
    font-size: 30px;
    margin-right: 10px;
    color: var(--app-primary-2);
}
.why-choose-us .feature h2 {
    margin: 0;
    font-size: 1.2em;
    color: var(--app-primary-5);
}
.why-choose-us .feature p {
    color:var(--app-primary-5);
    margin: 5px 0 0 0;
}