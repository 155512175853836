/* styles.css */
@import 'ruler.css';
@import 'why_choose_us.css';

:root {
 --app-primary-1: #092b42;
 --app-primary-2: #105186;
 --app-primary-3: #f0f2f3;
 --app-primary-4: #fdb338;
 --app-primary-5: #171C1D;

 /* --app-primary-1: #5d8696;
 --app-primary-2: #8fafcc;
 --app-primary-3: #d9dad4;
 --app-primary-4: #cca885;
 --app-primary-5: #7babaf;
*/

 /* --app-primary-1: #425d5f;
 --app-primary-2: #bacacb;
 --app-primary-3: #f8f7f2;
 --app-primary-4: #fde7a2;
 --app-primary-5: #faa943; */

 /* --app-primary-1: #534739;
 --app-primary-2: #9E8774;
 --app-primary-3: #f0f2f1;
 --app-primary-4: #1f2d33;
 --app-primary-5: #0d0d0c; */


 /* --app-primary-1: #213271;
 --app-primary-2: #8fb9d1;
 --app-primary-3: #ddfafb;
 --app-primary-4: #e86349;
 --app-primary-5: #252c38; */

 /* --app-primary-1: #3a4276;
 --app-primary-2: #9dbdba;
 --app-primary-3: #fbb042;
 --app-primary-4: #ec6a52;
 --app-primary-5: #f387ad; */

}


html {
    font-size: 16px;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
    font-family: inherit; /*monospace|serif*/ 
    margin: 0;
    padding: 0;
    /* height: 120vh; */
    background: linear-gradient(to right, #f8f9fa, #e9ecef);
}

.navbar {
    background-color: white;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    box-shadow: 0 4px 2px -2px #8080802e;
    z-index: 1000;
}

.navbar a {
    text-decoration: none;
    color: var(--app-primary-5);
}

.banner {
    height: 55vh;
    background-color: var(--app-primary-1);
    /* background-image:url('home.png'); */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    position: relative;
    /* background-size: cover; *//* Cover the entire area */
    /*background-position: center; /* Center the image */
    /*background-repeat: no-repeat; /* Prevent repeating the image */
    
}

.banner-content {
    max-width: 800px;
}

.banner h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: var(--app-primary-4);
}

.banner p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: var(--app-primary-3);
}

.navbar-icon {
    align-items: center;
    display: flex;
}
.navbar-icon img {
    width: 2em
}

.navbar-icon span{
    margin-left: 1em;
    color: var(--app-primary-5);
}


.upload-container {
    min-height: calc(100vh - 360px); /* Subtract navbar and footer height */
    background-color: white;
}

.header-photo-container {
    width: 100%;
    height: 300px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(40,40,184,1) 35%, rgba(0,212,255,1) 100%);
    justify-content: center;
    display: flex;
    align-items: center;
}

.logo-container {
    color: white;
    font-size: 80px;
    width: 150px;
    height: 150px;
    background: #e9ecef;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.hero {
    text-align: center;
    padding: 2em 0;
    background-color: #ffffff;
    margin-bottom: 2em;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hero h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
}

.hero p {
    font-size: 1.2em;
}


.customized-upload-section {
    text-align: center;
    background-color: #ffffff;
    padding: 3em;
    display: block;
    flex-direction: row;
    row-gap: 0.5rem;
    justify-content: space-around;
    margin: 0 auto;
    max-width: max-content;
}


.upload-section {
    text-align: center;
    background-color: var(--app-primary-1);
    padding: 2em 4em;
    flex-wrap: wrap;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.customized-upload-section {
    background-color: white;
}

.upload-section form,
.customized-upload-section form {
    display: inline;
    text-align: left;
    margin-bottom: 1em;
}

.customized-upload-section form {
    width: 100%;
    max-width: 600px;
}

.customized-upload-section .input-group {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    margin: 0.5em 0;
    label {
        color: var(--app-primary-2)
    }
    .input-group-item,
    select {
        border-radius: 4px;
        padding: 0.5em;
        background-color: var(--app-primary-3);
        border: solid 0.5px var(--app-primary-1);
        &:focus {
            border: solid var(--app-primary-2);
            z-index: 9;
        }
    }
}

.customized-upload-section input[type=button], input[type=submit], input[type=reset] {
    background-color: var(--app-primary-2);
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

.customized-upload-section input[type=submit]:hover {
background-color: var(--app-primary-4); /* change to your desired color */
color: white; /* change to your desired color */
}

.customized-upload-section input[type=file] {
    background-color: var(--app-primary-3);
    color: var(--app-primary-5);
    padding: 16px 32px;
    margin: 4px 2px;
    border-radius: 4px;
}

.upload-section p {
    margin-bottom: 1em;
}

.upload-section input[type="file"] {
    display: none;
    margin-bottom: -2em;
}

/* .upload-section label {
    background-color: var(--app-primary-2);
    color: var(--app-primary-3);
    padding: 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  } */

  .upload-section .row-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 0.5em;
    margin: 0.5em 0;
  }

  .upload-button {
    max-width: 400px;
  }

  #actual_upload-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--app-primary-5);
    font-weight: 600;
    width: 300px;
    height: 200px;
    background-color: #e5e7eb;
    justify-content: center;
    border: dashed 1px var(--app-primary-1);
  }

  input::-webkit-file-upload-button {
    display: none;
    color: transparent;
  }
  .upload-section .hidden {
    color: transparent;
    background-color: transparent;
  }

.upload-section button {
    padding: 0.5em 1em;
    font-size: 1em;
    background-color: var(--app-primary-1);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.3rem;
    border: solid 1px white;
}

.upload-section .size-text {
    font-size: 12px;
}

.upload-section button:hover {
    background-color: var(--app-primary-4);
}

#custom-upload-button:hover {
    background-color: var(--app-primary-2);
}

.result-section {
    position: relative;
}

.result-section,
.four-six-result-section {
    background-color: var(--app-primary-3);
}

.result-section,
.modify-section,
.four-six-result-section,
.donate-section,
.delete-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--app-primary-5)
}


.resize-tool {
    display: flex;
    flex-direction: row;
    column-gap: 0.5em;
    font-size: 1em;
    margin-top: 0.5em;
    justify-content: space-between;
    align-items: center;
    color: white;
}
.resize-tool label {
    width: 75px;
}

.resize-tool button {
    background-color: var(--app-primary-1) !important;
    color: var(--app-primary-4) !important;
    border: 1px solid var(--app-primary-4) !important;
}

.modify-section,
.donate-section,
.delete-section  {
    background-color: var(--app-primary-3);
}

.result-section h1 {
    font-size: 2em;
    margin-bottom: 1em;
}

.result-section p {
    font-size: 1em;
    margin: 1em;
    color: var(--app-primary-5);
}

.result-section .photo-display {
    display: flex;
    flex-direction: row;
    column-gap: 4em;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    background-color: var(--app-primary-1);
}

.suggest-msg {
    background-color: #e8cbd6;
    border: 1px solid #f2413a;
    border-radius: 5px;
    padding: 1em;
    width: 100%;
    .suggest-msg-text {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        color: #ff0402;
        text-align: left;
        column-gap: 1em;
        margin: 0.5em 0;
    }
}

.result-section button,
.modify-section button,
.four-six-result-section button,
.donate-section button,
.delete-section button {
    padding: 0.5em 1em;
    font-size: 1em;
    background-color: var(--app-primary-2);
    color: var(--app-primary-3);
    border: none;
    cursor: pointer;
    /* margin-top: 1em; */
    border-radius: 5px;
}

.remove-photo-message button {
    color: white;
    border: 1px solid var(--app-primary-2);
    background-color: var(--app-primary-2);
    margin-left: 1em;
}
.photo-display-compare {
    display: flex;
    width: 750px;
    height: 300px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.photo-modify-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 1em;
    flex-wrap: wrap;
}

.photo-modify-buttons a button {
    width: 150px;
}

.photo-vs-text {
    font-size: 3em; 
    font-weight: 600; 
    color: #0f5186;
}

.sample-section {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    padding: 1em;
    justify-content: center;
    align-items: center;
    border-top: 1px dashed var(--app-primary-4);
    .example-title {
        color: var(--app-primary-5);
    }
}

.us-passport-photo {
    position: relative;
    
    .image-backdrop.ch-show {
        visibility: visible;
        opacity: 1;
    }

    .image-backdrop.us-show {
        visibility: visible;
        opacity: 1;
    }

    .image-backdrop {
        position: absolute;
        background-color: #171c1d7a;
        height: 100%;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: hidden;
        opacity: 0;

        .image-loader {
            --d:22px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            color: var(--app-primary-4);
            box-shadow: 
            calc(1*var(--d))      calc(0*var(--d))     0 0,
            calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
            calc(0*var(--d))      calc(1*var(--d))     0 2px,
            calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
            calc(-1*var(--d))     calc(0*var(--d))     0 4px,
            calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
            calc(0*var(--d))      calc(-1*var(--d))    0 6px;
            animation: l27 1s infinite steps(8);
        }
        @keyframes l27 {
            100% {transform: rotate(1turn)}
        }
    }
}

.image-backdrop.show {
    visibility: visible;
    opacity: 1;
}


.us-passport-photo img,
.us-passport-photo-compare img {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.us-passport-photo-compare img {
    cursor: pointer;
}

.us-passport-photo-compare{
    width: 40%;
    height: 100%;
    background: #fdb337;
    border-radius: 50%;
}

.four-six-photo {
    margin-top: 2em;
}
.four-six-photo img {
    max-width: 100%;
    height: auto;
    width: 750px;
}

.result-section button:hover {
    background-color: #0055a5;
}

.about-section {
    text-align: center;
    background-color: #ffffff;
}

.about-section .banner,
#result-banner {
    height: 20vh;
}

.short-banner {
    height: 20vh;
}


.about-section h1 {
    font-size: 2.5em;
}

.about-section .about-content {
    padding: 2em;
    max-width: 800px;
    margin: 0 auto;
}

.example-section,
.faq-section {
    flex-direction: column;
    display: flex;
    justify-content: center;
    row-gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em;
    background-color: var(--app-primary-1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.faq-section .faq-container {
    padding: 1em 3em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.accordion {
    background-color: white;
    max-width: 1000px;
    .accordion-item {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 0 1em;
      button[aria-expanded='true'] {
        font-weight: 600;
      }
    }
    button {
      position: relative;
      display: block;
      text-align: left;
      width: 100%;
      padding: 1em 0;
      color: var(--app-primary-5);
      border: none;
      background: none;
      outline: none;
      font-weight: 600;
      &:hover, &:focus {
        cursor: pointer;
        color: var(--app-primary-2);
        &::after {
          cursor: pointer;
          color: var(--app-primary-2);
          border: 1px solid var(--app-primary-2);
        }
      }
      .accordion-title {
        padding: 1em 1.5em 1em 0;
      }
      .icon {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 18px;
        width: 22px;
        height: 22px;
        border: 1px solid var(--app-primary-4);
        border-radius: 22px;
        color: white;
        background-color: var(--app-primary-4);
        &::before {
          display: block;
          position: absolute;
          content: '';
          top: 9px;
          left: 5px;
          width: 10px;
          height: 2px;
          background: currentColor;
        }
        &::after {
          display: block;
          position: absolute;
          content: '';
          top: 5px;
          left: 9px;
          width: 2px;
          height: 10px;
          background: currentColor;
        }
      }
    }
    button[aria-expanded='true'] {
      color: var(--app-primary-2);
      .icon {
        &::after {
          width: 0;
        }
      }
      + .accordion-content {
        opacity: 1;
        max-height: 15em;
        transition: all 200ms linear;
        will-change: opacity, max-height;
      }
    }
    .accordion-content {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: opacity 200ms linear, max-height 200ms linear;
      will-change: opacity, max-height;
      p {
        font-weight: 300;
        margin: 0.5em 0 1em 0;
      }
    }
  }

.example-containers {
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.example-section #before-img,
.example-section #middle-img  {
    width: 270px;
}

.example-section #after-img {
    width: 164px;
}


.example-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 300px;
    height: 300px;
    background-color: var(--app-primary-1);
    border: 1px solid white;
}

.example-container .step-number {
    color: #fdb337;
    font-weight: 600;
    font-size: 1.2em;
}

.example-container #example-number-text {
    margin-bottom: 2.1em;
}

.example-container .example-number-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
}

.example-text {
    color: var(--app-primary-3);
    width: 100%;
    border-radius: 5px;
    text-align: center;
}

.example-title,
.faq-title {
    font-size: 1.5em;
    padding-top: 1em;
    color: white;
    font-weight: 200;
    letter-spacing: 2px;
}



/* Spinner and Backdrop Styles */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    flex-direction: column;
}

.backdrop.show {
    visibility: visible;
    opacity: 1;
}

.spinner {
    border: 10px solid #f3f3f3;
    border-top: 10px solid var(--app-primary-4);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* HTML: <div class="loader"></div> */
.spinner-loader {
    width: 120px;
    height: 20px;
    -webkit-mask:linear-gradient(90deg,#000 70%,#0000 0) 0/20%;
    background:
     linear-gradient(var(--app-primary-4) 0 0) 0/0% no-repeat
     #ddd;
    animation: l4 2s infinite steps(6);
  }
  @keyframes l4 {
      100% {background-size:120%}
  }


.footer {
    background-color: var(--app-primary-1);
    color: var(--app-primary-3);
    padding: 2em;
    font-size: 0.8em;
    text-align: center;
}

.footer-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.footer p {
    margin-bottom: 0.5em;
}

.footer a {
    color: #fff;
}

.footer a:hover {
    text-decoration: underline;
}

.donate-link {
    color: var(--app-primary-4);
    font-weight: 600;
}

.how-to-section {
    text-align: left;
    padding-top: 1em;
}

.buttons-how-to {
    margin: 0 auto;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
    justify-content: space-around;
    flex-wrap: wrap;
}

.how-to-notes {
    max-width: 400px;
    margin-left: 4em;
}
 
.requirement-link-section {
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    justify-content: center;
}

ol { 
    list-style: none;
    margin: 0;
    padding: 0;
    counter-reset: gradient-counter;
}
.alternating-colors {
    margin: 0 auto;
    color: white;
}
li { 
position: relative;
counter-increment: gradient-counter;
line-height: 25px;

&:before {
    content: counter(gradient-counter);
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 2px solid var(--app-primary-4);
    position: absolute;
    top: 1px;
    left: -42px;
    padding-top: 0px;
    padding-left: 9px;
    background-color: var(--app-primary-1);
    color: var(--app-primary-4);
}
}

strong { color: var(--app-primary-5); }

.blog-section {
    .banner {
        height: 20vh;
    }
    .blog-card {
        margin: 0 auto;
        max-width: 800px;
        display: flex;
        align-items: center;
        position: relative;
        .text-overlay {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid white;
            padding: 1em;
            background-color: white;
            justify-content: space-between;

            .blog-title {
                font-size: 1em;
                font-weight: 600;
                color: var(--app-primary-5);
            }

            .blog-read-more {
                background-color: var(--app-primary-4);
                padding: 0.5em 1em;
                color: var(--app-primary-3);
                position: absolute;
                right: 32px;
                text-align: center;
                border-radius: 5px;
            }
            
        }
    }
}

.blog-content-container {
    .banner {
        height: 20vh;
    }
    .blog-content {
        line-height: 1.6;
        margin: 20px;
        max-width: 800px;
        margin: auto;
    }

    h2 {
        color: #333;
    }
    h1 {
        text-align: center;
        margin-bottom: 30px;
        color: var(--app-primary-4);
    }
    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 10px 0;
    }
    .section {
        background-color: #f9f9f9;
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin: 20px 0;
    }
    .engage {
        text-align: center;
    }
}

@media only screen and (max-width: 575px) {
    .navbar-icon span {
        display: none;
    }

    .banner h1 {
        font-size: 2rem;
    }

    .how-to-section {
        padding: 0.5em; 
    }

    .example-text {
        margin: 0.5em;
    }

    .example-container {
        margin: 5px;
    }

    .example-containers .example-container {
        width: 300px;
        height: 300px;
        .example-number-text {
            font-size: 1.2em;
            margin-bottom: unset;
        }
    }

    .example-container #example-number-text {
        margin-bottom: unset;
    }
    .example-section #before-img,
    .example-section #middle-img {
        width: 250px;
    }

    .example-section #after-img {
        width: 160px;
        height: 160px;
    }

    .photo-display-compare {
        width: 110vw;
        height: 180px;
    }

    .photo-display-compare .us-passport-photo-compare img {
        height: 160px;
        width: 160px;
    }

    .photo-vs-text {
        font-size: 2em;
    }

    .backdrop-text {
        padding: 2em;
    }

    .faq-section .faq-container {
        padding: 0;

        .accordion {
            button {
                icon {

                }
            }
        }
    }
    .result-section .photo-display {
        width: 100%;
        min-width: 300px;
        flex-wrap: wrap;
        height: 560px;
    }

    .customized-upload-section {
        padding: 1em;

    }

    .upload-section {
        padding: unset;
    }

    .how-to-notes {
        padding: 1em;
        margin-left: 3em;
    }

    .example-title {
        font-size: 1.2em;
    }

    .donate-section {
        padding: 0 2em;
    }

    .blog-image {
        display: none;
    }
    .blog-section {
        & .blog-card {
            & .text-overlay {
                .blog-read-more {
                        padding: 0.4em;
                        position: relative;
                    }
                }
        }   
    }
}


@media only screen and (min-width: 576px) and (max-width: 780) {
    .example-containers .example-container {
        width: 190px;
        height: 190px;
        .example-number-text {
            font-size: 0.7em;
        }
    }
    .example-section #before-img,
    .example-section #middle-img {
        width: 93px;
    }

    .example-section #after-img {
        width: 125px;
        height: 125px;
    }
}

.make-your-photo-button {
    color: white;
    background-color: var(--app-primary-2);
    padding: 16px 32px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    font-size: 1.2em;
}
.make-your-photo-button:hover {
    background-color: var(--app-primary-4); /* change to your desired color */
    color: white; /* change to your desired color */
}

.remove-photo-message {
    width: 100%;
    color: var(--app-primary-2) !important;
    font-weight: 500;
}

.delete-photo-message {
    width: 100%;
    color: #fd3838 !important;
    font-weight: 500;
}

.delete-photo-message button {
    background-color: var(--app-primary-2);
    margin-left: 0.5em;
}

.alert_color {
    color: red !important;
}

.suggest-msg ol {
    text-align: left;
    padding-left: 2.5em;
}